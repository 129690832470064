.wrapper {
    display: grid;
    grid-template-columns: 12% 76% 12%;
    grid-template-rows: auto;
}

.middle {
    grid-column: 2 / 3;
}

.side {
    /* position: fixed; */
    right: 0;
    grid-column: 3 / end;
}