.wrapper {
    display: grid;
    grid-template-columns: 12% 76% 12%;
    grid-template-rows: auto;
}

.middle {
    grid-column: 2 / 3;
}

.side {
    /* position: fixed; */
    right: 0;
    grid-column: 3 / end;
}

.wrapper2 {
    display: grid;
    grid-template-columns: 20% 10% 50% 20%;
    grid-template-rows: auto;
}
.middle2 {
    grid-column: 3 / 4;
}
.side_left {
    grid-column: 2 / 3;   
}