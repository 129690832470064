.wrapper {
    display: grid;
    grid-template-columns: 22% 78%;
    grid-template-rows: auto;
}



.left {
    /* position: fixed; */
    right: 0;
    grid-column: 1 / 2;
    align-content: center;
    /* justify-self: center; */
}

.right {
    grid-column: 2 / end;
    justify-self: center;

}
/* 
.wrapper2 {
    display: grid;
    grid-template-columns: 20% 10% 50% 20%;
    grid-template-rows: auto;
}
.middle2 {
    grid-column: 3 / 4;
}
.side_left {
    grid-column: 2 / 3;   
} */